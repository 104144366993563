import React, { useState, useEffect } from 'react'
import styles from './Docs.module.scss'
import Button from '../common/Button'
import GridItem from './GridItem'
import { Link } from 'gatsby'
import Categories from './Categories'
import {
  ArrowRightIcon,
  ArrowUpOnSquareIcon,
  BoltIcon,
  CubeIcon,
  CursorArrowRippleIcon,
  MapIcon,
  UsersIcon
} from '@heroicons/react/24/outline'

const Docs = ({ data, categories, title, subtitle }) => {
  const [activeCategory, setActiveCategory] = useState('Explore all')
  const [searchInput, setSearchInput] = useState('')
  const [tag, setTag] = useState('')

  useEffect(() => {
    if (searchInput !== '') {
      setActiveCategory('Explore all')
    }
  }, [searchInput])

  useEffect(() => {
    if (activeCategory !== 'Explore all') {
      setSearchInput('')
    }
  }, [activeCategory])

  useEffect(() => {
    if (title === 'Documentation') {
      setTag('docs')
    } else if (title === 'Guides') {
      setTag('guides')
    }
  }, [title])

  return (
    <>
      <div className={styles.section}>
        <div className={styles.content}>
          <div className={styles.layout}>
            <div className={styles.leftContainer}>
              <Categories docs={data} categories={categories} tag={tag} />
            </div>
            <div className={styles.rightContainer}>
              <div className={styles.headerContent}>
                <div className={styles.heading}>
                  <div className={styles.breadcrumb}>
                    <Link to="/help/"> Help</Link> /{' '}
                    <Link to={`/help/${tag}/`}>{title}</Link>
                  </div>{' '}
                  <h1>{title}</h1>
                  <p>{subtitle}</p>
                </div>
                <div className={styles.buttonGrid}>
                  {categories.map(category => (
                    <Link
                      to={`/${tag}/${category.label
                        .replace(/([a-z])([A-Z])/g, '$1 $2')
                        .toLowerCase()
                        .replace(/\s+/g, '-')
                        .replace(/[’']/g, '')}/`}
                    >
                      <div className={styles.buttonCard}>
                        <div className={styles.buttonContent}>
                          <h4 className={styles.text}>{category.label}</h4>
                          <p className={styles.subText}>{category.subtitle}</p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
              {categories.map(category => (
                <div className={styles.cardsSection}>
                  <div className={styles.cardsSectionHeader}>
                    <h3>{category.label}</h3>
                    <div className={styles.seeAllContainer}>
                      <Button
                        variant="minimal"
                        href={`/${tag}/${category.label
                          .replace(/([a-z])([A-Z])/g, '$1 $2')
                          .toLowerCase()
                          .replace(/\s+/g, '-')
                          .replace(/[’']/g, '')}`}
                        rightIcon={<ArrowRightIcon width={20} />}
                      >
                        See all {tag}
                      </Button>
                    </div>
                  </div>

                  <div className={styles.grid}>
                    {data
                      .filter(d => d.topic === category.label)
                      .slice(0, 2)
                      .map(doc => (
                        <GridItem
                          title={doc.title}
                          image={doc.featuredImage}
                          readtime={doc.readTime}
                          tag={tag}
                        />
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Docs
